<template>
  <div class="news">
    <div class="head">
      <i class="el-icon-message-solid"></i>
      <span>我的消息</span>
    </div>

    <div class="content-panel">
      <div class="table-wrap">
        <div class="filter-action">
          <div class="left">
            <span class="active">全部消息</span>
            <span>未读</span>
            <span>已读</span>
          </div>
          <div class="right">
            <el-button size="mini" round plain>删除</el-button>
            <el-button size="mini" round plain>标记已读</el-button>
            <el-button size="mini" round plain>全部已读</el-button>
          </div>
        </div>

        <el-table
          size="mini"
          ref="multipleTable"
          :data="[]"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          header-row-class-name="table-head-row"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="标题内容">
            <template slot-scope="scope">{{ scope.row.date }}</template>
          </el-table-column>
          <el-table-column prop="name" label="时间" width="120">
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页 -->
      <div class="pagination-wrap">
        <ori-pagination />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
};
</script>

<style lang="scss" scoped>
.news {
  .head {
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0px 25px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    vertical-align: middle;

    i {
      margin-right: 4px;
    }
  }

  .content-panel {
    background: #ffffff;
    overflow: hidden;
    padding: 25px 25px 25px 25px;
    min-height: 650px;
    position: relative;

    .pagination-wrap {
      text-align: center;
      margin: 30px 0;
    }

    .table-wrap {
      .filter-action {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          span {
            cursor: pointer;
            display: inline-block;
            padding-right: 20px;
            margin-right: 20px;
            border-right: 1px solid #ccc;

            font-size: 14px;

            line-height: 17px;

            &:hover,
            &:active,
            &.active {
              color: red;
            }
          }

          span:last-child {
            border-right: none;
          }
        }
      }

      ::v-deep .table-head-row th {
        background: #f4f5f9;
        color: #666;
      }
    }
  }
}
</style>